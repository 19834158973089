var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "아이디",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "username",
      "placeholder": "아이디를 입력하세요.",
      "rules": [_vm.rules.username],
      "persistent-hint": "",
      "outlined": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "비밀번호",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "password",
      "placeholder": "비밀번호를 입력하세요.",
      "rules": [_vm.rules.password],
      "persistent-hint": "",
      "outlined": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "비밀번호 확인",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "password2",
      "placeholder": "비밀번호를 입력하세요.",
      "rules": [_vm.rules.passwordConfirm],
      "persistent-hint": "",
      "outlined": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.user, "passwordConfirm", $$v);
      },
      expression: "user.passwordConfirm"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "이름",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "name",
      "placeholder": "이름을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "닉네임",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "name",
      "placeholder": "닉네임을 입력하세요.",
      "persistent-hint": "",
      "outlined": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.user, "nickname", $$v);
      },
      expression: "user.nickname"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "연락처",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "placeholder": "전화번호를 입력하세요.",
      "readonly": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.certify
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-sm-100 w-100 w-sm-100px",
    attrs: {
      "color": "grey-6"
    },
    on: {
      "click": _vm.certify
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("본인인증")])])], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "이메일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "이메일을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email1", $$v);
      },
      expression: "user.email1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-combobox', {
    attrs: {
      "prefix": "@",
      "items": _vm.emailItems,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email2", $$v);
      },
      expression: "user.email2"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "성별",
      "pointer": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.sex,
      callback: function ($$v) {
        _vm.$set(_vm.user, "sex", $$v);
      },
      expression: "user.sex"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "남성",
      "value": "남성"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "여성",
      "value": "여성"
    }
  })], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "생년월일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "yyyy",
      "outlined": "",
      "hide-details": "",
      "maxLength": "4"
    },
    model: {
      value: _vm.user.bornAt1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "bornAt1", $$v);
      },
      expression: "user.bornAt1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "mm",
      "outlined": "",
      "hide-details": "",
      "maxLength": "2"
    },
    model: {
      value: _vm.user.bornAt2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "bornAt2", $$v);
      },
      expression: "user.bornAt2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "dd",
      "outlined": "",
      "hide-details": "",
      "maxLength": "2"
    },
    model: {
      value: _vm.user.bornAt3,
      callback: function ($$v) {
        _vm.$set(_vm.user, "bornAt3", $$v);
      },
      expression: "user.bornAt3"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "주소",
      "pointer": ""
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address1", $$v);
      },
      expression: "user.address1"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "outlined": "",
      "color": "grey-b3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey-6--text"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("회원가입하기")])], 1), _c('kcp-cert', {
    ref: "kcp-cert",
    on: {
      "input": _vm.verify
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-8 mb-lg-20 text-right"
  }, [_c('p', {
    staticClass: "page-text page-text--sm grey-6--text line-height-1"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")])]);

}]

export { render, staticRenderFns }