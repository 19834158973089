<template>
    <div>
        <!-- 정보입력 -->
        <!-- 정보입력: 일반 -->
        <div class="mb-8 mb-lg-20 text-right">
            <p class="page-text page-text--sm grey-6--text line-height-1"><span class="primary--text">*</span> 필수입력 항목입니다.</p>
        </div>

        <div class="form--primary">
            <form-row tit="아이디" pointer>
                <v-text-field v-model="user.username" @input="input" type="text" name="username" placeholder="아이디를 입력하세요." :rules="[rules.username]" persistent-hint outlined />
            </form-row>
            <form-row tit="비밀번호" pointer>
                <v-text-field v-model="user.password" @input="input" type="password" name="password" placeholder="비밀번호를 입력하세요." :rules="[rules.password]" persistent-hint outlined />
            </form-row>
            <form-row tit="비밀번호 확인" pointer>
                <v-text-field v-model="user.passwordConfirm" @input="input" type="password" name="password2" placeholder="비밀번호를 입력하세요." :rules="[rules.passwordConfirm]" persistent-hint outlined />
            </form-row>
            <form-row tit="이름" pointer>
                <v-text-field v-model="user.name" @input="input" type="text" name="name" placeholder="이름을 입력하세요." outlined hide-details />
            </form-row>
            <form-row tit="닉네임" pointer>
                <v-text-field v-model="user.nickname" @input="input" type="text" name="name" placeholder="닉네임을 입력하세요." persistent-hint outlined />
            </form-row>
            <form-row tit="연락처" pointer>
                <v-row class="row--x-small">
                    <v-col>
                        <v-text-field v-model="user.phone" placeholder="전화번호를 입력하세요." readonly outlined hide-details @click="certify"/>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <v-btn @click="certify" color="grey-6" class="h-sm-100 w-100 w-sm-100px"><span class="white--text">본인인증</span></v-btn>
                    </v-col>
                </v-row>
            </form-row>
            <form-row tit="이메일" pointer>
                <v-row class="row--x-small" align="center">
                    <v-col cols="12" md="6">
                        <v-text-field v-model="user.email1" placeholder="이메일을 입력하세요." outlined hide-details />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-combobox v-model="user.email2" prefix="@" :items="emailItems" outlined hide-details />
                    </v-col>
                </v-row>
            </form-row>
            <form-row tit="성별" pointer>
                <v-radio-group v-model="user.sex" row hide-details @input="input">
                    <v-radio label="남성" value="남성" />
                    <v-radio label="여성" value="여성" />
                </v-radio-group>
            </form-row>
            <form-row tit="생년월일" pointer>
                <v-row class="row--x-small">
                    <v-col cols="4">
                        <v-text-field v-model="user.bornAt1" placeholder="yyyy" outlined hide-details maxLength="4" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="user.bornAt2" placeholder="mm" outlined hide-details maxLength="2" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="user.bornAt3" placeholder="dd" outlined hide-details maxLength="2" />
                    </v-col>
                </v-row>
            </form-row>
            <form-row tit="주소" pointer>
                <v-select v-model="user.address1" :items="items" outlined hide-details />
            </form-row>
        </div>

        <div class="v-btn--group">
            <v-btn @click="$router.go(-1)" outlined color="grey-b3" class="min-w-120px min-w-lg-160px">
                <span class="grey-6--text">취소</span>
            </v-btn>
            <v-btn @click="submit()" color="primary" class="min-w-120px min-w-lg-160px">회원가입하기</v-btn>
        </div>
        <kcp-cert ref="kcp-cert" @input="verify"></kcp-cert>
    </div>
</template>

<script>
import FormRow from "@/components/dumb/form-row.vue";
import api from "@/api";
// import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";

export default {
    components: {
        FormRow,
        // DaumPostcode,
        KcpCert,
    },
    props: ["value"],
    data() {
        return {
            user: this.$props.value || {},

            emailItems: ["google.com", "naver.com", "nate.com", "daum.net"],

            items: [
                { text: "주소를 입력해주세요", value: null },
                { text: "서울특별시", value: "서울특별시" },
                { text: "부산광역시", value: "부산광역시" },
                { text: "인천광역시", value: "인천광역시" },
                { text: "대구광역시", value: "대구광역시" },
                { text: "광주광역시", value: "광주광역시" },
                { text: "대전광역시", value: "대전광역시" },
                { text: "울산광역시", value: "울산광역시" },
                { text: "세종특별자치시", value: "세종특별자치시" },
                { text: "경기도", value: "경기도" },
                { text: "강원도", value: "강원도" },
                { text: "충청북도", value: "충청북도" },
                { text: "충청남도", value: "충청남도" },
                { text: "경상북도", value: "경상북도" },
                { text: "경상남도", value: "경상남도" },
                { text: "전라북도", value: "전라북도" },
                { text: "전라남도", value: "전라남도" },
                { text: "제주특별자치도", value: "제주특별자치도" },
            ],

            check: false,

            rules: {
                username: (value) => {
                    const pattern = /^[a-zA-Z0-9]{3,12}$/;
                    return pattern.test(value) || "3~12 영문·숫자 조합";
                },
                password: (value) => {
                    const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
                    return pattern.test(value) || "영문·숫자·특수문자 조합 8~20자리";
                },
                passwordConfirm: (value) => {
                    return value === this.user.password || "동일한 비밀번호를 입력해주세요";
                },
            },
        };
    },
    watch: {
        "user.email"() {
            this.$emit("input", this.user);
        },
        "user.email1"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },
        "user.email2"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },

        "user.phone"() {
            this.$emit("input", this.user);
        },
        "user.phone1"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone2"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone3"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },

        "user.bornAt"() {
            this.$emit("input", this.user);
        },
        "user.bornAt1"() {
            if(this.user.bornAt1.length === 4 && this.user.bornAt1 > this.$dayjs().format("YYYY")) this.user.bornAt1 = this.$dayjs().format("YYYY");
            if(this.user.bornAt1.length === 4 && this.user.bornAt1 < "1900") this.user.bornAt1 = "1900";
            this.$set(this.user, "bornAt", this.user.bornAt1 && this.user.bornAt2 && this.user.bornAt3 ? `${this.user.bornAt1}-${this.user.bornAt2}-${this.user.bornAt3}` : null);
        },
        "user.bornAt2"() {
            if(this.user.bornAt2 > "12") this.user.bornAt2 = "12"
            this.$set(this.user, "bornAt", this.user.bornAt1 && this.user.bornAt2 && this.user.bornAt3 ? `${this.user.bornAt1}-${this.user.bornAt2}-${this.user.bornAt3}` : null);
        },
        "user.bornAt3"() {
            if(this.user.bornAt3 > "31") this.user.bornAt3 = "31"
            this.$set(this.user, "bornAt", this.user.bornAt1 && this.user.bornAt2 && this.user.bornAt3 ? `${this.user.bornAt1}-${this.user.bornAt2}-${this.user.bornAt3}` : null);
        },
    },
    methods: {
        input() {
            this.$emit("input", this.user);
        },
        cancel() {
            this.$router.go(-1);
        },

        submit() {
            if (this.validate()) this.$emit("submit", this.user);
        },

        certify() {
            this.$refs["kcp-cert"].auth();
        },

        searchAddress() {
            this.$refs["daum-postcode"].open();
        },
        setAddress({ postcode, address }) {
            this.user.postcode = postcode;
            this.user.address1 = address;
        },

        verify(payload) {
            this.user._certification = payload._certification;
            this.user.phone = payload.phone;
        },

        validate() {
            try {
                if (!this.user.username) throw new Error("아이디를 입력해주세요.");
                if (!this.user.password) throw new Error("비밀번호를 입력해주세요.");

                if (!this.checkPassword(this.user.password)) return false;
                if (!this.user.passwordConfirm) throw new Error("비밀번호 확인을 입력해주세요.");
                if (this.user.password != this.user.passwordConfirm) throw new Error("비밀번호를 확인해주세요");

                if (!this.user.name) throw new Error("이름을 입력해주세요.");
                if (!this.user.nickname) throw new Error("닉네임을 입력해주세요.");

                if (!this.user.phone) {
                    if (!this.user._certification) {
                        this.certify();
                        return false;
                    }
                }

                if (!this.user.email1 || !this.user.email2) throw new Error("이메일을 입력해주세요.");
                if (!this.user.sex) throw new Error("성별을 선택해주세요.");
                if (!this.user.bornAt) throw new Error("생년월일을 입력해주세요.");
                // if (!this.user.postcode) throw new Error("우편번호를 입력해주세요");
                // if (!this.user.address1) throw new Error("주소를 입력해주세요");
                // if (!this.user.address2) throw new Error("상세주소를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");

            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");

            return true;
        },
        async confirmNickname() {
            let { success } = await api.v1.users.confirmNickname({ params: { nickname: this.user.nickname } });
            if (success) {
            }
        },
    },
};
</script>
